import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Container, { Col, Splash } from '../components/global/Container'
import Content, { HTMLContent } from '../components/global/Content'

export const ContactThanksTemplate = ({
  title,
  content,
  contentComponent,
  helmet,
}) => {
  const PageContent = contentComponent || Content

  return (
    <>
      {helmet || ''}
      <Splash>
        <Container>
          <Col tw="w-full">
            <h1>{title}</h1>
            <PageContent className="content" content={content} />
          </Col>
        </Container>
      </Splash>
    </>
  )
}

ContactThanksTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
  helmet: PropTypes.object,
}

const ContactThanksPage = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <ContactThanksTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
        helmet={
          <Helmet titleTemplate="%s - Techna NDT">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
      />
    </Layout>
  )
}

ContactThanksPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ContactThanksPage

export const ContactThanksQuery = graphql`
  query ContactThanksPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
